<template>
  <div>
    <div class="container mt-5 mb-5">
      <div class="mb-md-4 pb-5">
        <b-card class="card cart cart2">
          <h5>Nákupný zoznam - Krok 2: Suroviny</h5>
          <p style="font-size: 80%">Toto je zoznam surovín, ktoré sú potrebné na prípravu receptúr, ktoré ste si naplánovali.</p>
          <div class="d-flex cart-header">
            <div class="cart-header--title">Položka</div>
            <div class="sub">Množstvo</div>
            <div class="sub">Na sklade</div>
            <div class="sub">

            </div>
          </div>
          <div class="ingredients">
            <div class="recipe-item" v-for="(item, i) in list" :key="i" :class="{ products: item.group_id === 'null'}">
              <template v-if="item.group_id !== 'null'">
                <div class="d-flex recipe-item--content">
                  <div class="sub-title">
                    {{item.ingredient.name}}
                  </div>
                  <div class="sub">{{item.volume_with_unit}}</div>
                  <div class="sub">
                    <b-input-group :append="item.ingredient.unit.name">
                      <b-form-input type="number" v-model="item.on_stock" :number="true" debounce="500"  min="-0" step="1"></b-form-input>
                    </b-input-group>
                  </div>
                  <div class="sub">
                    <b-dropdown right class="policy-drop">
                      <template #button-content>
                        <span>...</span>
                      </template>
                      <b-dropdown-item @click.prevent="onRemoveCartRecipe(item)">Vymazať ingredienciu</b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <SubmitFooter :on-submit="onSubmit" submit-text="Odoslať"/>
  </div>
</template>

<script>
import Vue from 'vue'
import {mapActions, mapGetters, mapMutations} from "vuex"
import format from '@/plugins/app/formatters'
import SearchProductsModal from "@/plugins/app/cart/search-products-modal"
import SubmitFooter from "@/plugins/app/_layouts/components/submit-footer"
import SubmitModal from "./modal.vue"

export default Vue.extend({
  components: {SubmitFooter},
  data: function () {
    return {
      toggleState: {}
    }
  },
  watch: {
    cart: function () {
      this.cart.recipes.forEach( (item) => {
        if(!this.toggleState[item.group_id])
          this.toggleState[item.group_id] = false

        if(item.group_id === 'null') {
          this.toggleState[item.group_id] = true
        }

        this.$forceUpdate()
      })
    }
  },
  computed: {
    ...mapGetters('cart', ['list', 'cart']),
  },
  async mounted() {
    console.log('cart mounted')
    await this.loadList()
    this.$forceUpdate()
  },
  methods: {
    ...mapActions('cart', [
      'removeCart', 'removeCartRecipe',
      'removeCartItem', 'changeItemPortions',
      'submitCart', 'loadList'
    ]),
    ...mapMutations('cart', ['updateCart', 'removeIngredient']),
    onChangePortions(quantity, id) {
      this.changeItemPortions([{id, quantity}])
    },
    onToggle(id) {
      this.toggleState[id] = !this.toggleState[id]
      this.$forceUpdate()
    },
    onOpenModal() {
      this.$modal.show( SearchProductsModal )
    },

    onRemoveCart() {
      this.removeCart()
    },
    onRemoveCartRecipe(ingredient) {
      this.removeIngredient(ingredient.id)
    },
    onRemoveCartItem(id) {
      this.removeCartItem(id)
    },

    onSubmit() {
      this.$modal.show( SubmitModal, {
        payload: this.list.map(item => ({id: item.id, on_stock: item.on_stock}))
      } )
    },

    navTo: function (to) {
      this.$router.push(to)
    },

    formatDay: format.day,
    formatDayId: format.dayId,
    formatDayName: format.dayName,
    formatNow: format.now,
    formatPrice: format.price,
    formatIsPast: format.isPast,
    formatIngredientVolume: format.ingredientVolume,
  }
})
</script>
<style lang="scss" scoped>
.cart {
  .ingredients {
    //background: #FCFCFC;
    //border: 1px solid #E7E7E7;
    border-radius: 4px;
    //padding: 5px 10px;
  }

  .recipe-item--content {
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #E7E7E7;
  }
}


</style>

<style lang="scss">
.dropdown-menu {
  .dropdown-item {
    padding:8px;
  }
}

.sub-title {
  font-weight: 400;
  font-size: 12px;
  //line-height: 14px;
}

.flex-grow-1 {
  padding-left: 20px;
}

.jump-wrap{
  position: relative;

  @for $i from 1 through 999 {
    &:nth-last-child(#{$i}) {
      z-index: #{$i};
    }
  }

  .stop-fucking-jumping {
    height: 40px;
    overflow: hidden;
    position: relative;
    .collapse-item {
      background-color: #FCFCFC;
    }
  }


  .absolute-drop{
    position: absolute;
    right: 0;
    top: 5px;
    z-index: 1;

    .dropdown-menu{
      z-index: 10;
    }
    button{
      border: none;
      color: #C4C4C4;
      background-color: transparent!important;
      &:focus{
        background-color: transparent!important;
      }
      &:active{
        background-color: transparent!important;
      }
      span {
        display: block;
        margin-top: -6px;
        color: #A6A6A6;
      }
      &:after {
        display: none;
      }
    }
  }
}

.toggle-btn{
  box-shadow:none !important;
  outline:0px !important;
  transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  &.toggled {
    transform: rotate(90deg);
  }
}

.old-price{
  text-decoration: line-through;
}
.total {
  padding-top: 15px;
  border-top: 1px solid #F1F1F1;

  .total-right{
    display: flex;
    align-items: center;
    position: absolute;
    right: 30px;
    .dropdown-toggle{
      margin-left: 20px;
      background-color: transparent !important;
      border: none;
      padding: 0.375rem 0.75rem;
      &:after {
        display: none;
      }
      span{
        display: block;
        margin-top: -8px;
        color: #A6A6A6;
      }
    }
  }
  .total-title {
    font-size: 16px;
    font-weight: 500;
  }
  .total-actions{
    .btn-secondary {
      padding: 8px 10px;
      font-size: 12px;
    }
    .price{
      font-size: 16px;
      font-weight: 500;
    }
  }
}
</style>
