<template>
  <b-card class="card" header="Odoslať nákupný zoznam">
    <b-button @click="onClose" variant="secondary" class="close-button">×</b-button>
    <b-form-group :label="title" :label-for="name" >
      <b-form-input ref="focusInput" :id="name" v-model="email" :state="validateState(name)" type="text"/>
      <b-form-invalid-feedback>{{warning}}</b-form-invalid-feedback>
    </b-form-group>
    <b-button @click="onSubmit">Odoslať</b-button>
  </b-card>

</template>
<script>
import Vue from 'vue'
import {mapActions, mapGetters} from "vuex"
import { required, email } from "vuelidate/lib/validators"

export default Vue.extend({
  props: ['payload'],
  data: function () {
    return {
      email: '',
      name: 'email',
      title: 'Poslať na email',
      warning: 'Toto pole musí obsahovať email.'
    }
  },
  computed: {
    ...mapGetters('wAuth', ['user']),
  },
  mounted() {
    this.email = this.user.email
    this.$nextTick(_ => this.$refs.focusInput.focus())
  },
  validations: {
    email: {required, email}
  },
  methods: {
    ...mapActions('cart', ['finishListOrder', 'loadCart', 'loadList']),
    validateState(field) {
      if(!this.$v[field])
        return null

      const { $dirty, $error } = this.$v[field]
      return $dirty ? !$error : null
    },
    onClose: function() {
      this.$emit('close')
    },

    onSubmit: async function () {
      this.validateState('email')
      this.$v.$touch()
      if (this.$v.$anyError)
        return

      this.$v.$reset()
      this.$wToast.clear_loading()

      await this.finishListOrder({
        email: this.email,
        items: this.payload
      })

      await new Promise(r => setTimeout(r, 500))
      await this.loadCart()
      await this.loadList()
      this.$wToast.success('Email bol odoslaný!', true)
      await this.$router.push('/app/daily')

      this.$emit('close', true)
    }
  }
})
</script>

<style lang="scss" scoped>
.close-button {
  color: #C4C4C4;
  padding: 0;
  background-color: transparent;
  position: absolute;
  top: 23px;
  right: 30px;
  font-size: 24px;
  border: none;
  font-weight: 300;
}
</style>
